export default {
	reset_password: (
		<svg width="86" height="80" viewBox="0 0 86 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M50.6667 34.662H45.3333V26.662C45.3333 23.7233 42.9387 21.3286 40 21.3286C37.0613 21.3286 34.6667 23.7233 34.6667 26.662V34.662H29.3333V26.662C29.3333 20.7793 34.1173 15.9953 40 15.9953C45.8827 15.9953 50.6667 20.7793 50.6667 26.662V34.662Z"
				fill="#1D1952"
			/>
			<path
				d="M83.6587 24.1873C82.2987 23.6433 80.7413 24.3046 80.1973 25.6753L78.6027 29.6646C74.0267 12.6033 58.48 -0.00469208 40 -0.00469208C17.9413 -0.00469208 0 17.9366 0 39.9953C0 62.054 17.9413 79.9953 40 79.9953C50.6827 79.9953 60.7307 75.8353 68.288 68.2833C69.328 67.2433 69.328 65.5526 68.288 64.5126C67.248 63.4726 65.5573 63.4726 64.5173 64.5126C57.9627 71.0566 49.2587 74.662 40 74.662C20.8853 74.662 5.33333 59.11 5.33333 39.9953C5.33333 20.8806 20.8853 5.32864 40 5.32864C56.6453 5.32864 70.5813 17.126 73.904 32.7953L68.5547 27.4406C67.5147 26.4006 65.824 26.4006 64.784 27.4406C63.744 28.4806 63.744 30.1713 64.784 31.2113L75.4507 41.878C75.952 42.39 76.6347 42.662 77.3333 42.662C77.5093 42.662 77.6907 42.646 77.872 42.6086C78.3039 42.5203 78.707 42.3258 79.045 42.0428C79.383 41.7597 79.6452 41.397 79.808 40.9873L85.1413 27.654C85.6853 26.2833 85.024 24.7313 83.6587 24.1873Z"
				fill="#1D1952"
			/>
			<path
				d="M53.3333 31.9953H26.6667C25.1947 31.9953 24 33.19 24 34.662V55.9953C24 57.4673 25.1947 58.662 26.6667 58.662H53.3333C54.8053 58.662 56 57.4673 56 55.9953V34.662C56 33.19 54.8053 31.9953 53.3333 31.9953Z"
				fill="#3B65E3"
			/>
		</svg>
	),
	visible: (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15.8983 7.68881C15.7553 7.49328 12.3496 2.90112 7.9999 2.90112C3.65018 2.90112 0.244309 7.49328 0.101528 7.68862C0.0355528 7.77901 0 7.88802 0 7.99992C0 8.11182 0.0355528 8.22083 0.101528 8.31122C0.244309 8.50675 3.65018 13.0989 7.9999 13.0989C12.3496 13.0989 15.7553 8.50672 15.8983 8.31137C15.9643 8.22103 16 8.11201 16 8.00009C16 7.88817 15.9643 7.77915 15.8983 7.68881ZM7.9999 12.044C4.79587 12.044 2.02084 8.99606 1.19937 7.99965C2.01978 7.00237 4.789 3.95606 7.9999 3.95606C11.2038 3.95606 13.9786 7.00344 14.8004 8.00037C13.98 8.99762 11.2108 12.044 7.9999 12.044Z"
				fill="#1D1952"
			/>
			<path
				d="M7.9998 4.83521C6.25474 4.83521 4.83496 6.25498 4.83496 8.00004C4.83496 9.7451 6.25474 11.1649 7.9998 11.1649C9.74487 11.1649 11.1646 9.7451 11.1646 8.00004C11.1646 6.25498 9.74487 4.83521 7.9998 4.83521ZM7.9998 10.1099C6.83637 10.1099 5.88993 9.16345 5.88993 8.00004C5.88993 6.83663 6.8364 5.89017 7.9998 5.89017C9.16321 5.89017 10.1097 6.83663 10.1097 8.00004C10.1097 9.16345 9.16324 10.1099 7.9998 10.1099Z"
				fill="#1D1952"
			/>
		</svg>
	),
	not_visible: (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2_20)">
				<mask id="mask0_2_20" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x={0} y={0} width={16} height={16}>
					<path d="M16 0H0V16H16V0Z" fill="white" />
				</mask>
				<g mask="url(#mask0_2_20)">
					<path
						d="M9.98636 8.13478L10.8951 7.22603C10.9612 7.47341 11 7.73203 11 8.00003C11 9.65428 9.65424 11 7.99999 11C7.73199 11 7.47336 10.9613 7.22599 10.8952L8.13474 9.98641C8.61507 9.95378 9.06732 9.74823 9.40775 9.4078C9.74818 9.06736 9.95374 8.61512 9.98636 8.13478ZM15.937 7.75716C15.8755 7.64653 15.0032 6.11816 13.2982 4.82291L12.5706 5.55053C13.8139 6.46466 14.6016 7.53078 14.9117 8.00078C14.3191 8.90316 11.9944 12 7.99999 12C7.40099 12 6.84399 11.9234 6.31986 11.8012L5.49824 12.6228C6.25361 12.8544 7.08386 13 7.99999 13C13.2341 13 15.8291 8.43703 15.937 8.24291C15.9783 8.16863 16 8.08503 16 8.00003C16 7.91503 15.9783 7.83144 15.937 7.75716ZM13.8535 2.85353L2.85349 13.8535C2.75586 13.9512 2.62786 14 2.49999 14C2.37211 14 2.24411 13.9512 2.14649 13.8535C2.10003 13.8071 2.06317 13.752 2.03803 13.6914C2.01288 13.6307 1.99994 13.5657 1.99994 13.5C1.99994 13.4344 2.01288 13.3694 2.03803 13.3087C2.06317 13.248 2.10003 13.1929 2.14649 13.1465L3.54599 11.747C1.28349 10.3812 0.133861 8.37053 0.0629858 8.24291C0.0216782 8.1686 0 8.08499 0 7.99997C0 7.91495 0.0216782 7.83134 0.0629858 7.75703C0.170861 7.56303 2.76586 3.00003 7.99999 3.00003C9.35336 3.00003 10.5256 3.30878 11.5277 3.76528L13.1465 2.14653C13.3419 1.95116 13.6582 1.95116 13.8535 2.14653C14.0487 2.34191 14.0489 2.65828 13.8535 2.85353ZM4.29161 11.0014L5.55749 9.73553C5.20786 9.24503 4.99999 8.64691 4.99999 8.00003C4.99999 6.34578 6.34574 5.00003 7.99999 5.00003C8.64686 5.00003 9.24499 5.20791 9.73549 5.55741L10.7591 4.53378C9.95036 4.20991 9.03136 4.00003 7.99999 4.00003C4.00561 4.00003 1.68086 7.09691 1.08836 7.99928C1.46461 8.56953 2.54611 10.013 4.29161 11.0014ZM6.28336 9.00953L9.00949 6.28341C8.71211 6.10778 8.36974 6.00003 7.99999 6.00003C6.89724 6.00003 5.99999 6.89728 5.99999 8.00003C5.99999 8.36978 6.10774 8.71216 6.28336 9.00953Z"
						fill="#ABABAB"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_2_20">
					<rect width={16} height={16} fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	// search: (
	// 	<svg width={16} height={16} className="position-absolute searchIcon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
	// 		<circle cx="6.95455" cy="6.95455" r="5.95455" stroke="black" strokeWidth={2} />
	// 		<path d="M12 12L15 15" stroke="black" strokeWidth={2} strokeLinecap="round" />
	// 	</svg>
	// ),
	search: (
		<svg width={20} height={20} className="searchIcon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.7919 18.7768L15.7209 14.7057C17.0493 13.1487 17.8564 11.1275 17.8564 8.92771C17.8564 4.00676 13.8496 0 8.92869 0C4.00773 0 0.000976562 4.00676 0.000976562 8.92771C0.000976562 13.8487 4.00773 17.8554 8.92869 17.8554C11.1356 17.8554 13.1497 17.0484 14.7067 15.7199L18.7777 19.7909C19.4562 20.4695 20.449 19.441 19.7848 18.7839L19.7919 18.7768ZM1.42941 8.92771C1.42941 4.79239 4.79337 1.42843 8.92869 1.42843C13.064 1.42843 16.428 4.79239 16.428 8.92771C16.428 13.063 13.064 16.427 8.92869 16.427C4.79337 16.427 1.42941 13.063 1.42941 8.92771Z"
				fill="#003464"
			/>
		</svg>
	),
	searchClear: (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="searchIcon">
			<path d="M15 8C15 4.13542 11.8646 1 8 1C4.13542 1 1 4.13542 1 8C1 11.8646 4.13542 15 8 15C11.8646 15 15 11.8646 15 8Z" stroke="#3AA61C" strokeMiterlimit={10} />
			<path d="M10.3327 10.3329L5.66602 5.66626M5.66602 10.3329L10.3327 5.66626" stroke="#3AA61C" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	),
	close: (
		<svg width={16} height={16} className="position-absolute searchIcon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.46602 8.00015L15.6963 1.76973C16.1018 1.36446 16.1018 0.709174 15.6963 0.303952C15.2911 -0.101317 14.6358 -0.101317 14.2306 0.303952L8.0001 6.53432L1.76987 0.303952C1.36441 -0.101317 0.709368 -0.101317 0.304098 0.303952C-0.101366 0.709222 -0.101366 1.36446 0.304098 1.76973L6.53427 8.00015L0.304147 14.2305C-0.101317 14.6358 -0.101317 15.2911 0.304147 15.6963C0.400279 15.7927 0.514513 15.8692 0.640285 15.9213C0.766058 15.9734 0.90089 16.0001 1.03703 16C1.30235 16 1.56777 15.8984 1.76992 15.6963L8.0001 9.46592L14.2306 15.6963C14.3267 15.7927 14.4409 15.8691 14.5667 15.9212C14.6925 15.9734 14.8273 16.0001 14.9635 16C15.2288 16 15.4942 15.8984 15.6963 15.6963C16.1018 15.291 16.1018 14.6358 15.6963 14.2305L9.46602 8.00015Z"
				fill="black"
			/>
		</svg>
	),
	openEye: (
		<svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_19_1781)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.98532 8.99977C4.98532 6.78702 6.78515 4.98645 9.00029 4.98645C11.2131 4.98645 13.0129 6.78698 13.0129 8.99977C13.0129 11.213 11.2131 13.0128 9.00029 13.0128C6.78511 13.0128 4.98532 11.213 4.98532 8.99977ZM0.103836 8.61689C-0.0346121 8.86731 -0.0346121 9.13224 0.103836 9.38289C1.90128 12.6106 5.30993 14.6157 9.00029 14.6157C12.6882 14.6157 16.0969 12.6107 17.8943 9.38289C18.0352 9.13224 18.0352 8.86731 17.8943 8.61689C16.0969 5.38916 12.6883 3.38428 9.00029 3.38428C5.30993 3.38428 1.90128 5.38916 0.103836 8.61689ZM9.00029 6.30171C7.51079 6.30171 6.30054 7.51192 6.30054 8.99977C6.30054 10.4876 7.51075 11.6978 9.00029 11.6978C10.4874 11.6978 11.6976 10.4876 11.6976 8.99977C11.6976 7.51192 10.4874 6.30171 9.00029 6.30171Z"
					fill="#003464"
				/>
			</g>
			<defs>
				<clipPath id="clip0_19_1781">
					<rect width={18} height={18} fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	closeEye: (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_946_3942)">
				<path
					d="M9.98636 8.13478L10.8951 7.22603C10.9612 7.47341 11 7.73203 11 8.00003C11 9.65428 9.65424 11 7.99999 11C7.73199 11 7.47336 10.9613 7.22599 10.8952L8.13474 9.98641C8.61507 9.95378 9.06732 9.74823 9.40775 9.4078C9.74818 9.06736 9.95374 8.61512 9.98636 8.13478ZM15.937 7.75716C15.8755 7.64653 15.0032 6.11816 13.2982 4.82291L12.5706 5.55053C13.8139 6.46466 14.6016 7.53078 14.9117 8.00078C14.3191 8.90316 11.9944 12 7.99999 12C7.40099 12 6.84399 11.9234 6.31986 11.8012L5.49824 12.6228C6.25361 12.8544 7.08386 13 7.99999 13C13.2341 13 15.8291 8.43703 15.937 8.24291C15.9783 8.16863 16 8.08503 16 8.00003C16 7.91503 15.9783 7.83144 15.937 7.75716ZM13.8535 2.85353L2.85349 13.8535C2.75586 13.9512 2.62786 14 2.49999 14C2.37211 14 2.24411 13.9512 2.14649 13.8535C2.10003 13.8071 2.06317 13.752 2.03803 13.6914C2.01288 13.6307 1.99994 13.5657 1.99994 13.5C1.99994 13.4344 2.01288 13.3694 2.03803 13.3087C2.06317 13.248 2.10003 13.1929 2.14649 13.1465L3.54599 11.747C1.28349 10.3812 0.133861 8.37053 0.0629858 8.24291C0.0216782 8.1686 0 8.08499 0 7.99997C0 7.91495 0.0216782 7.83134 0.0629858 7.75703C0.170861 7.56303 2.76586 3.00003 7.99999 3.00003C9.35336 3.00003 10.5256 3.30878 11.5277 3.76528L13.1465 2.14653C13.3419 1.95116 13.6582 1.95116 13.8535 2.14653C14.0487 2.34191 14.0489 2.65828 13.8535 2.85353ZM4.29161 11.0014L5.55749 9.73553C5.20786 9.24503 4.99999 8.64691 4.99999 8.00003C4.99999 6.34578 6.34574 5.00003 7.99999 5.00003C8.64686 5.00003 9.24499 5.20791 9.73549 5.55741L10.7591 4.53378C9.95036 4.20991 9.03136 4.00003 7.99999 4.00003C4.00561 4.00003 1.68086 7.09691 1.08836 7.99928C1.46461 8.56953 2.54611 10.013 4.29161 11.0014ZM6.28336 9.00953L9.00949 6.28341C8.71211 6.10778 8.36974 6.00003 7.99999 6.00003C6.89724 6.00003 5.99999 6.89728 5.99999 8.00003C5.99999 8.36978 6.10774 8.71216 6.28336 9.00953Z"
					fill="#ABABAB"
				/>
			</g>
			<defs>
				<clipPath id="clip0_946_3942">
					<rect width={16} height={16} fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	admin_dashboard: (
		<svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.93745 6.00005H1.31245C0.588727 6.00005 0 5.41132 0 4.68745V1.31245C0 0.588727 0.588727 0 1.31245 0H6.93745C7.66132 0 8.25005 0.588727 8.25005 1.31245V4.68745C8.25005 5.41132 7.66132 6.00005 6.93745 6.00005ZM6.93745 18H1.31245C0.588727 18 0 17.4113 0 16.6875V8.81255C0 8.08868 0.588727 7.49995 1.31245 7.49995H6.93745C7.66132 7.49995 8.25005 8.08868 8.25005 8.81255V16.6875C8.25005 17.4113 7.66132 18 6.93745 18ZM16.6875 18H11.0625C10.3387 18 9.74995 17.4113 9.74995 16.6875V13.3125C9.74995 12.5887 10.3387 12 11.0625 12H16.6875C17.4113 12 18 12.5887 18 13.3125V16.6875C18 17.4113 17.4113 18 16.6875 18ZM16.6875 10.5H11.0625C10.3387 10.5 9.74995 9.91132 9.74995 9.18745V1.31245C9.74995 0.588727 10.3387 0 11.0625 0H16.6875C17.4113 0 18 0.588727 18 1.31245V9.18745C18 9.91132 17.4113 10.5 16.6875 10.5Z"
				fill="white"
			/>
		</svg>
	),
	bill_management: (
		<svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.1232 14.0756H0V15.6412C0.00116767 16.5842 0.732002 17.3654 1.67297 17.4294C1.69304 17.4265 1.71332 17.4248 1.73361 17.4246H10.8371C10.3776 16.9268 10.1227 16.274 10.1232 15.5964V14.0756Z"
				fill="white"
			/>
			<path
				d="M2.83545 2.12325L2.75977 13.2106H10.5556C10.7945 13.2106 10.9881 13.4043 10.9881 13.643V15.5966C10.9881 16.5379 11.739 17.3407 12.6607 17.4248H12.8082C12.8318 17.425 12.8555 17.427 12.8788 17.4311C13.8677 17.3975 14.6521 16.5861 14.6523 15.5966V2.26112C14.6522 1.80484 14.8032 1.36137 15.0818 1.00003H3.97847C3.3529 0.995276 2.84172 1.49785 2.83545 2.12325ZM4.68729 3.95533H8.78332C9.02218 3.95533 9.21579 4.14895 9.21579 4.3878C9.21579 4.6267 9.02218 4.82027 8.78332 4.82027H4.68734C4.44844 4.82027 4.25486 4.6267 4.25486 4.3878C4.25486 4.14895 4.4484 3.95533 4.68729 3.95533ZM4.68729 7.0475H12.3279C12.5668 7.0475 12.7604 7.24112 12.7604 7.47998C12.7604 7.71887 12.5668 7.91245 12.3279 7.91245H4.68734C4.44844 7.91245 4.25486 7.71887 4.25486 7.47998C4.25486 7.24112 4.4484 7.0475 4.68729 7.0475ZM4.68729 10.1397H12.3279C12.5668 10.1397 12.7604 10.3333 12.7604 10.5722C12.7604 10.811 12.5668 11.0046 12.3279 11.0046H4.68734C4.44844 11.0046 4.25486 10.811 4.25486 10.5722C4.25486 10.3333 4.4484 10.1397 4.68729 10.1397ZM16.7585 1.03956C16.0787 1.03498 15.5234 1.58132 15.5172 2.26112V8.24844H17.9999V2.26112C17.9936 1.58128 17.4383 1.0348 16.7585 1.03956Z"
				fill="white"
			/>
		</svg>
	),
	customers: (
		<svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9 7.26437C10.6892 7.26437 12.0586 5.89499 12.0586 4.20578C12.0586 2.51656 10.6892 1.14718 9 1.14718C7.31079 1.14718 5.94141 2.51656 5.94141 4.20578C5.94141 5.89499 7.31079 7.26437 9 7.26437Z"
				fill="white"
			/>
			<path
				d="M15.1875 7.26434C16.2554 7.26434 17.1211 6.39864 17.1211 5.33074C17.1211 4.26285 16.2554 3.39715 15.1875 3.39715C14.1196 3.39715 13.2539 4.26285 13.2539 5.33074C13.2539 6.39864 14.1196 7.26434 15.1875 7.26434Z"
				fill="white"
			/>
			<path
				d="M2.8125 7.26434C3.88039 7.26434 4.74609 6.39864 4.74609 5.33074C4.74609 4.26285 3.88039 3.39715 2.8125 3.39715C1.74461 3.39715 0.878906 4.26285 0.878906 5.33074C0.878906 6.39864 1.74461 7.26434 2.8125 7.26434Z"
				fill="white"
			/>
			<path
				d="M4.71762 9.00074C3.95648 8.37714 3.26718 8.45968 2.38711 8.45968C1.07086 8.45968 0 9.52421 0 10.8324V14.6718C0 15.2399 0.463711 15.7019 1.03395 15.7019C3.4958 15.7019 3.19922 15.7464 3.19922 15.5957C3.19922 12.8751 2.87698 10.8799 4.71762 9.00074Z"
				fill="white"
			/>
			<path
				d="M9.83693 8.47371C8.29976 8.3455 6.96364 8.47519 5.81119 9.42645C3.88262 10.9712 4.25377 13.0512 4.25377 15.5957C4.25377 16.2689 4.8015 16.8268 5.48494 16.8268C12.9058 16.8268 13.2011 17.0662 13.6412 16.0917C13.7855 15.7622 13.746 15.8669 13.746 12.7143C13.746 10.2102 11.5778 8.47371 9.83693 8.47371ZM15.6128 8.45965C14.7279 8.45965 14.0424 8.37795 13.2822 9.0007C15.1091 10.866 14.8006 12.725 14.8006 15.5957C14.8006 15.7473 14.5544 15.7018 16.929 15.7018C17.5196 15.7018 17.9999 15.2234 17.9999 14.6352V10.8323C17.9999 9.52418 16.929 8.45965 15.6128 8.45965Z"
				fill="white"
			/>
		</svg>
	),
	staticPage: (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.0778 4.98932C11.8033 4.98877 10.7704 3.95589 10.7701 2.68163V0H3.63462C2.87091 0.00216923 2.25217 0.620908 2.25 1.38462V16.6154C2.25217 17.3791 2.87091 17.9978 3.63462 18H14.3746C15.1385 17.9978 15.757 17.3791 15.7592 16.6154V4.98932H13.0778ZM4.74702 7.06154H9.73611C9.99106 7.06154 10.1976 7.26817 10.1976 7.52308C10.1976 7.77798 9.99106 7.98462 9.73611 7.98462H4.74702C4.49206 7.98462 4.28548 7.77798 4.28548 7.52308C4.28548 7.26817 4.49206 7.06154 4.74702 7.06154ZM13.7284 14.9677H4.74702C4.49206 14.9677 4.28548 14.7611 4.28548 14.5062C4.28548 14.2512 4.49206 14.0446 4.74702 14.0446H13.7284C13.9834 14.0446 14.19 14.2512 14.19 14.5062C14.19 14.7611 13.9834 14.9677 13.7284 14.9677ZM13.7284 11.4739H4.74702C4.49206 11.4739 4.28548 11.2672 4.28548 11.0124C4.28548 10.7574 4.49206 10.5509 4.74702 10.5509H13.7284C13.9834 10.5509 14.19 10.7574 14.19 11.0124C14.19 11.2672 13.9834 11.4739 13.7284 11.4739Z"
				fill="white"
			/>
			<path d="M13.0781 4.06626H15.118L11.6934 0.650879V2.68165C11.6939 3.44605 12.3134 4.06552 13.078 4.06626H13.0781Z" fill="white" />
		</svg>
	),
	emailTemplate: (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_787_14)">
				<path
					d="M0.0351562 7.88681V17.2147L6.03165 12.5508L0.0351562 7.88681ZM15.6797 5.46011V8.32802L17.4506 6.95063L15.6797 5.46011ZM9.33957 0.12385C9.2445 0.0438605 9.12425 0 9 0C8.87575 0 8.7555 0.0438605 8.66043 0.12385L7.26209 1.30078H10.7379L9.33957 0.12385ZM2.32031 5.46011L0.549387 6.95063L2.32031 8.32802V5.46011ZM7.07154 13.0781L0.743414 18H17.2566L10.9285 13.0781H7.07154ZM11.9683 12.5508L17.9648 17.2147V7.88677L11.9683 12.5508ZM8.86707 6.30576C8.43933 6.30576 7.99689 6.72166 7.99689 7.41761C7.99689 8.12517 8.42846 8.60048 8.83157 8.60048C9.25604 8.60048 9.60412 8.10383 9.62916 7.47938L9.62982 7.34681C9.61088 6.73073 9.30259 6.30576 8.86707 6.30576Z"
					fill="white"
				/>
				<path
					d="M10.9285 12.0234L14.625 9.14832V2.88281C14.625 2.59158 14.3889 2.35547 14.0977 2.35547H3.90234C3.61111 2.35547 3.375 2.59158 3.375 2.88281V9.14836L7.07154 12.0234H10.9285ZM5.28251 8.43427C5.03068 7.47679 5.15732 6.46738 5.63913 5.59202C6.12074 4.71698 6.90557 4.06969 7.84905 3.76935C8.84443 3.45241 9.90134 3.54579 10.8249 4.03225C11.7487 4.51874 12.4235 5.33703 12.7251 6.33642C12.7268 6.34184 12.7283 6.34732 12.7298 6.3528C12.9302 7.10374 12.8709 7.81263 12.5373 8.65128C12.3119 9.21814 11.7591 9.82389 10.8839 9.82389C10.5664 9.82389 10.2619 9.69367 10.0265 9.45717C9.97106 9.40119 9.92061 9.34045 9.87574 9.27566C9.58018 9.51606 9.22177 9.65514 8.8316 9.65514C8.32191 9.65514 7.82634 9.40771 7.472 8.97627C7.13036 8.5603 6.94223 8.00677 6.94223 7.41758C6.94223 6.82576 7.14241 6.27813 7.50593 5.87563C7.86438 5.47871 8.36054 5.25104 8.86711 5.25104C9.14572 5.25104 9.41048 5.31745 9.64839 5.44257C9.69451 5.19813 9.90879 5.01346 10.1661 5.01346H10.1688C10.46 5.0149 10.695 5.25217 10.6935 5.5434L10.679 8.47315C10.6785 8.56311 10.7141 8.65283 10.7742 8.71316C10.7949 8.73408 10.8368 8.76913 10.8839 8.76913C11.3094 8.76913 11.4966 8.41409 11.5573 8.26147C11.8036 7.64212 11.8503 7.15447 11.7129 6.63289C11.4924 5.90987 11.0027 5.31788 10.3335 4.9654C9.66185 4.61166 8.89305 4.54384 8.16898 4.77429C6.74167 5.22865 5.92182 6.71843 6.30253 8.16595C6.61866 9.36802 7.70523 10.2315 8.94488 10.2658C9.23597 10.2739 9.4655 10.5164 9.45742 10.8075C9.44951 11.0937 9.21502 11.3203 8.93057 11.3203C8.92561 11.3203 8.92069 11.3202 8.9157 11.3201C8.09849 11.2975 7.29179 11.0072 6.64418 10.5026C5.97136 9.9785 5.50055 9.26329 5.28251 8.43427Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_787_14">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	setting: (
		<svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.4606 10.9725L15.8061 9.67876C15.8271 9.47925 15.8451 9.24602 15.8451 8.99926C15.8451 8.7525 15.8278 8.51927 15.8061 8.31976L17.4621 7.02527C17.7696 6.78227 17.8536 6.35101 17.6579 5.99179L15.9373 3.015C15.7536 2.67901 15.3508 2.5065 14.9421 2.65725L12.9899 3.44099C12.6156 3.17099 12.2233 2.943 11.8176 2.76075L11.5206 0.690012C11.4718 0.297 11.1298 0 10.7256 0H7.27562C6.87136 0 6.53014 0.297 6.48211 0.684L6.18437 2.76226C5.79136 2.93927 5.40514 3.16427 5.01437 3.4425L3.05687 2.65651C2.69009 2.51476 2.25134 2.67374 2.06909 3.00825L0.346362 5.98876C0.143123 6.33227 0.227112 6.77851 0.541373 7.02752L2.19586 8.32127C2.1696 8.57401 2.15687 8.79377 2.15687 9.00004C2.15687 9.2063 2.16964 9.42602 2.19586 9.67954L0.539862 10.974C0.23235 11.2178 0.1491 11.649 0.34485 12.0075L2.06536 14.9843C2.24912 15.3195 2.64811 15.4935 3.0606 15.342L5.01286 14.5583C5.38636 14.8275 5.7786 15.0555 6.18437 15.2385L6.48137 17.3085C6.5301 17.703 6.87136 18 7.27636 18H10.7263C11.1306 18 11.4726 17.703 11.5206 17.316L11.8183 15.2385C12.2114 15.0608 12.5968 14.8365 12.9883 14.5575L14.9458 15.3435C15.0395 15.3802 15.1392 15.399 15.2399 15.399C15.5308 15.399 15.7986 15.24 15.9336 14.9925L17.6616 12C17.8536 11.649 17.7696 11.2178 17.4606 10.9725ZM9.0006 12C7.34611 12 6.00061 10.6545 6.00061 9C6.00061 7.34551 7.34611 6.00001 9.0006 6.00001C10.6551 6.00001 12.0006 7.34551 12.0006 9C12.0006 10.6545 10.6551 12 9.0006 12Z"
				fill="white"
			/>
		</svg>
	),
	logout: (
		<svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.4999 10.8336C12.0391 10.8336 11.6666 11.207 11.6666 11.6669V15.0003C11.6666 15.4594 11.2932 15.8335 10.8332 15.8335H8.3332V3.33365C8.3332 2.62198 7.87988 1.98617 7.19825 1.74949L6.95153 1.66695H10.8332C11.2932 1.66695 11.6666 2.04109 11.6666 2.50038V5.00036C11.6666 5.46023 12.0391 5.83363 12.4999 5.83363C12.9607 5.83363 13.3331 5.46023 13.3331 5.00036V2.50038C13.3331 1.12207 12.2115 0.000405273 10.8332 0.000405273H1.87498C1.84326 0.000405273 1.8167 0.0145848 1.78588 0.0187254C1.74573 0.0153661 1.70744 0.000366211 1.6667 0.000366211C0.747532 0.000405273 0 0.74782 0 1.66699V16.6668C0 17.3785 0.453316 18.0143 1.13495 18.251L6.14994 19.9227C6.31994 19.9752 6.489 20.0002 6.66661 20.0002C7.58579 20.0002 8.33316 19.2527 8.33316 18.3335V17.5002H10.8331C12.2114 17.5002 13.3331 16.3786 13.3331 15.0003V11.6669C13.3331 11.207 12.9607 10.8336 12.4998 10.8336H12.4999Z"
				fill="white"
			/>
			<path
				d="M19.7555 7.74428L16.4221 4.41103C16.3056 4.29441 16.1571 4.21497 15.9955 4.18275C15.8338 4.15054 15.6662 4.167 15.5139 4.23006C15.2031 4.35932 14.9997 4.66341 14.9997 5.00017V7.50014H11.6664C11.2064 7.50014 10.833 7.87338 10.833 8.33342C10.833 8.79345 11.2064 9.16669 11.6664 9.16669H14.9997V11.6667C14.9997 12.0034 15.2031 12.3075 15.5139 12.4368C15.6662 12.4998 15.8338 12.5163 15.9954 12.4841C16.1571 12.4519 16.3056 12.3725 16.4221 12.2559L19.7555 8.92255C20.0813 8.59677 20.0813 8.07006 19.7555 7.74428Z"
				fill="white"
			/>
		</svg>
	),

	active_customer: (
		<svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx={18} cy={18} r={18} fill="#EDF8FF" />
			<path
				d="M18 16.0715C19.8769 16.0715 21.3984 14.55 21.3984 12.6731C21.3984 10.7962 19.8769 9.27464 18 9.27464C16.1231 9.27464 14.6016 10.7962 14.6016 12.6731C14.6016 14.55 16.1231 16.0715 18 16.0715Z"
				fill="#003464"
			/>
			<path
				d="M24.875 16.0715C26.0615 16.0715 27.0234 15.1096 27.0234 13.923C27.0234 12.7365 26.0615 11.7746 24.875 11.7746C23.6885 11.7746 22.7266 12.7365 22.7266 13.923C22.7266 15.1096 23.6885 16.0715 24.875 16.0715Z"
				fill="#003464"
			/>
			<path
				d="M11.125 16.0715C12.3115 16.0715 13.2734 15.1096 13.2734 13.923C13.2734 12.7365 12.3115 11.7746 11.125 11.7746C9.93845 11.7746 8.97656 12.7365 8.97656 13.923C8.97656 15.1096 9.93845 16.0715 11.125 16.0715Z"
				fill="#003464"
			/>
			<path
				d="M13.2418 18.0008C12.3961 17.3079 11.6302 17.3996 10.6523 17.3996C9.18984 17.3996 8 18.5825 8 20.036V24.302C8 24.9332 8.51523 25.4465 9.14883 25.4465C11.8842 25.4465 11.5547 25.496 11.5547 25.3286C11.5547 22.3057 11.1966 20.0888 13.2418 18.0008Z"
				fill="#003464"
			/>
			<path
				d="M18.9302 17.4152C17.2222 17.2728 15.7377 17.4169 14.4571 18.4738C12.3143 20.1902 12.7267 22.5013 12.7267 25.3285C12.7267 26.0765 13.3353 26.6965 14.0946 26.6965C22.34 26.6965 22.6682 26.9625 23.1571 25.8797C23.3175 25.5135 23.2736 25.6299 23.2736 22.127C23.2736 19.3447 20.8645 17.4152 18.9302 17.4152ZM25.3478 17.3996C24.3646 17.3996 23.6029 17.3088 22.7583 18.0008C24.7882 20.0733 24.4454 22.1389 24.4454 25.3285C24.4454 25.497 24.1719 25.4465 26.8103 25.4465C27.4665 25.4465 28.0001 24.9148 28.0001 24.2613V20.0359C28.0001 18.5824 26.8103 17.3996 25.3478 17.3996Z"
				fill="#003464"
			/>
		</svg>
	),
	total_bill_amount: (
		<svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx={18} cy={18} r={18} fill="#F8F0FF" />
			<path
				d="M19.1232 23.0756H9V24.6412C9.00117 25.5842 9.732 26.3654 10.673 26.4294C10.693 26.4265 10.7133 26.4248 10.7336 26.4246H19.8371C19.3776 25.9268 19.1227 25.274 19.1232 24.5964V23.0756Z"
				fill="#7536A7"
			/>
			<path
				d="M11.8354 11.1232L11.7598 22.2106H19.5556C19.7945 22.2106 19.9881 22.4043 19.9881 22.643V24.5966C19.9881 25.5379 20.739 26.3407 21.6607 26.4248H21.8082C21.8318 26.425 21.8555 26.427 21.8788 26.4311C22.8677 26.3975 23.6521 25.5861 23.6523 24.5966V11.2611C23.6522 10.8048 23.8032 10.3614 24.0818 10H12.9785C12.3529 9.99528 11.8417 10.4979 11.8354 11.1232ZM13.6873 12.9553H17.7833C18.0222 12.9553 18.2158 13.1489 18.2158 13.3878C18.2158 13.6267 18.0222 13.8203 17.7833 13.8203H13.6873C13.4484 13.8203 13.2549 13.6267 13.2549 13.3878C13.2549 13.1489 13.4484 12.9553 13.6873 12.9553ZM13.6873 16.0475H21.3279C21.5668 16.0475 21.7604 16.2411 21.7604 16.48C21.7604 16.7189 21.5668 16.9124 21.3279 16.9124H13.6873C13.4484 16.9124 13.2549 16.7189 13.2549 16.48C13.2549 16.2411 13.4484 16.0475 13.6873 16.0475ZM13.6873 19.1397H21.3279C21.5668 19.1397 21.7604 19.3333 21.7604 19.5722C21.7604 19.811 21.5668 20.0046 21.3279 20.0046H13.6873C13.4484 20.0046 13.2549 19.811 13.2549 19.5722C13.2549 19.3333 13.4484 19.1397 13.6873 19.1397ZM25.7585 10.0396C25.0787 10.035 24.5234 10.5813 24.5172 11.2611V17.2484H26.9999V11.2611C26.9936 10.5813 26.4383 10.0348 25.7585 10.0396Z"
				fill="#7536A7"
			/>
		</svg>
	),
	paid_bill_amount: (
		<svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx={18} cy={18} r={18} fill="#F0FFEB" />
			<path
				d="M19.1232 22.0756H9V23.6412C9.00117 24.5842 9.732 25.3654 10.673 25.4294C10.693 25.4265 10.7133 25.4248 10.7336 25.4246H19.8371C19.3776 24.9268 19.1227 24.274 19.1232 23.5964V22.0756Z"
				fill="#419F20"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.7598 21.2106L11.8354 10.1232C11.8417 9.49785 12.3529 8.99528 12.9785 9.00003H24.0818C23.8032 9.36137 23.6522 9.80484 23.6523 10.2611V20.0149C21.6616 20.1863 20.0877 21.8157 20.0035 23.83C19.9933 23.7534 19.9881 23.6755 19.9881 23.5966V21.643C19.9881 21.4043 19.7945 21.2106 19.5556 21.2106H11.7598ZM17.7833 11.9553H13.6873C13.4484 11.9553 13.2549 12.1489 13.2549 12.3878C13.2549 12.6267 13.4484 12.8203 13.6873 12.8203H17.7833C18.0222 12.8203 18.2158 12.6267 18.2158 12.3878C18.2158 12.1489 18.0222 11.9553 17.7833 11.9553ZM21.3279 15.0475H13.6873C13.4484 15.0475 13.2549 15.2411 13.2549 15.48C13.2549 15.7189 13.4484 15.9124 13.6873 15.9124H21.3279C21.5668 15.9124 21.7604 15.7189 21.7604 15.48C21.7604 15.2411 21.5668 15.0475 21.3279 15.0475ZM21.3279 18.1397H13.6873C13.4484 18.1397 13.2549 18.3333 13.2549 18.5722C13.2549 18.811 13.4484 19.0046 13.6873 19.0046H21.3279C21.5668 19.0046 21.7604 18.811 21.7604 18.5722C21.7604 18.3333 21.5668 18.1397 21.3279 18.1397ZM24.5172 10.2611C24.5234 9.58132 25.0787 9.03498 25.7585 9.03956C26.4383 9.0348 26.9936 9.58128 26.9999 10.2611V16.2484H24.5172V10.2611Z"
				fill="#419F20"
			/>
			<path
				d="M24 21C22.3457 21 21 22.3457 21 24C21 25.6542 22.3457 27 24 27C25.6542 27 27 25.6542 27 24C27 22.3457 25.6542 21 24 21ZM25.5205 23.3642L23.8955 24.9892C23.8723 25.0125 23.8448 25.031 23.8145 25.0436C23.7841 25.0563 23.7516 25.0628 23.7188 25.0628C23.6859 25.0628 23.6534 25.0563 23.623 25.0436C23.5927 25.031 23.5652 25.0125 23.542 24.9892L22.7295 24.1767C22.7063 24.1535 22.6879 24.126 22.6753 24.0957C22.6628 24.0653 22.6563 24.0328 22.6563 24C22.6563 23.9672 22.6628 23.9347 22.6753 23.9043C22.6879 23.874 22.7063 23.8465 22.7295 23.8232C22.7527 23.8 22.7803 23.7816 22.8106 23.7691C22.8409 23.7565 22.8734 23.75 22.9062 23.75C22.9391 23.75 22.9716 23.7565 23.0019 23.7691C23.0322 23.7816 23.0598 23.8 23.083 23.8232L23.7188 24.459L25.167 23.0108C25.2139 22.9639 25.2775 22.9375 25.3437 22.9375C25.41 22.9375 25.4736 22.9639 25.5205 23.0108C25.5674 23.0576 25.5937 23.1212 25.5937 23.1875C25.5937 23.2538 25.5674 23.3174 25.5205 23.3642Z"
				fill="#419F20"
			/>
		</svg>
	),
	unpaid_bill_amount: (
		<svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx={18} cy={18} r={18} fill="#FFF3F3" />
			<path
				d="M19.1232 22.0756H9V23.6412C9.00117 24.5842 9.732 25.3654 10.673 25.4294C10.693 25.4265 10.7133 25.4248 10.7336 25.4246H19.8371C19.3776 24.9268 19.1227 24.274 19.1232 23.5964V22.0756Z"
				fill="#F33636"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.7598 21.2106L11.8354 10.1232C11.8417 9.49785 12.3529 8.99528 12.9785 9.00003H24.0818C23.8032 9.36137 23.6522 9.80484 23.6523 10.2611V20.0149C21.6616 20.1863 20.0877 21.8157 20.0035 23.83C19.9933 23.7534 19.9881 23.6755 19.9881 23.5966V21.643C19.9881 21.4043 19.7945 21.2106 19.5556 21.2106H11.7598ZM17.7833 11.9553H13.6873C13.4484 11.9553 13.2549 12.1489 13.2549 12.3878C13.2549 12.6267 13.4484 12.8203 13.6873 12.8203H17.7833C18.0222 12.8203 18.2158 12.6267 18.2158 12.3878C18.2158 12.1489 18.0222 11.9553 17.7833 11.9553ZM21.3279 15.0475H13.6873C13.4484 15.0475 13.2549 15.2411 13.2549 15.48C13.2549 15.7189 13.4484 15.9124 13.6873 15.9124H21.3279C21.5668 15.9124 21.7604 15.7189 21.7604 15.48C21.7604 15.2411 21.5668 15.0475 21.3279 15.0475ZM21.3279 18.1397H13.6873C13.4484 18.1397 13.2549 18.3333 13.2549 18.5722C13.2549 18.811 13.4484 19.0046 13.6873 19.0046H21.3279C21.5668 19.0046 21.7604 18.811 21.7604 18.5722C21.7604 18.3333 21.5668 18.1397 21.3279 18.1397ZM24.5172 10.2611C24.5234 9.58132 25.0787 9.03498 25.7585 9.03956C26.4383 9.0348 26.9936 9.58128 26.9999 10.2611V16.2484H24.5172V10.2611Z"
				fill="#F33636"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24 21C25.6557 21 27 22.3443 27 24C27 25.6557 25.6557 27 24 27C22.3443 27 21 25.6557 21 24C21 22.3443 22.3443 21 24 21ZM23.7039 24L22.8753 24.8288C22.8559 24.8483 22.8405 24.8713 22.83 24.8967C22.8195 24.9221 22.8141 24.9493 22.8141 24.9767C22.8141 25.0042 22.8195 25.0314 22.83 25.0568C22.8405 25.0822 22.8559 25.1052 22.8753 25.1246C22.8948 25.1441 22.9178 25.1595 22.9432 25.17C22.9686 25.1805 22.9958 25.1859 23.0233 25.1859C23.0507 25.1859 23.0779 25.1805 23.1033 25.17C23.1287 25.1595 23.1517 25.1441 23.1712 25.1246L24 24.2961L24.8288 25.1246C24.8483 25.1441 24.8713 25.1595 24.8967 25.17C24.9221 25.1805 24.9493 25.1859 24.9767 25.1859C25.0042 25.1859 25.0314 25.1805 25.0568 25.17C25.0822 25.1595 25.1052 25.1441 25.1246 25.1246C25.1441 25.1052 25.1595 25.0822 25.17 25.0568C25.1805 25.0314 25.1859 25.0042 25.1859 24.9767C25.1859 24.9493 25.1805 24.9221 25.17 24.8967C25.1595 24.8713 25.1441 24.8483 25.1246 24.8288L24.2961 24L25.1246 23.1712C25.1441 23.1517 25.1595 23.1287 25.17 23.1033C25.1805 23.0779 25.1859 23.0507 25.1859 23.0233C25.1859 22.9958 25.1805 22.9686 25.17 22.9432C25.1595 22.9178 25.1441 22.8948 25.1246 22.8753C25.1052 22.8559 25.0822 22.8405 25.0568 22.83C25.0314 22.8195 25.0042 22.8141 24.9767 22.8141C24.9493 22.8141 24.9221 22.8195 24.8967 22.83C24.8713 22.8405 24.8483 22.8559 24.8288 22.8753L24 23.7039L23.1712 22.8753C23.1517 22.8559 23.1287 22.8405 23.1033 22.83C23.0779 22.8195 23.0507 22.8141 23.0233 22.8141C22.9958 22.8141 22.9686 22.8195 22.9432 22.83C22.9178 22.8405 22.8948 22.8559 22.8753 22.8753C22.8559 22.8948 22.8405 22.9178 22.83 22.9432C22.8195 22.9686 22.8141 22.9958 22.8141 23.0233C22.8141 23.0507 22.8195 23.0779 22.83 23.1033C22.8405 23.1287 22.8559 23.1517 22.8753 23.1712L23.7039 24Z"
				fill="#F33636"
			/>
		</svg>
	),
	DashboardDateRange: (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_164_9)">
				<path
					d="M8 0C3.58887 0 0 3.58887 0 8C0 12.4111 3.58887 16 8 16C12.4111 16 16 12.4111 16 8C16 3.58887 12.4111 0 8 0ZM8 15C4.14013 15 1 11.8599 1 8C1 4.14013 4.14013 1 8 1C11.8599 1 15 4.14013 15 8C15 11.8599 11.8599 15 8 15ZM11.3535 7.6465C11.4 7.6929 11.4368 7.74801 11.462 7.80866C11.4871 7.86932 11.5 7.93434 11.5 8C11.5 8.06566 11.4871 8.13068 11.462 8.19134C11.4368 8.25199 11.4 8.3071 11.3535 8.3535L8.8535 10.8535C8.75588 10.9511 8.62788 11 8.5 11C8.37212 11 8.24412 10.9511 8.1465 10.8535C8.10004 10.8071 8.06319 10.752 8.03804 10.6913C8.01289 10.6307 7.99995 10.5657 7.99995 10.5C7.99995 10.4343 8.01289 10.3693 8.03804 10.3087C8.06319 10.248 8.10004 10.1929 8.1465 10.1465L9.793 8.5H5C4.72388 8.5 4.5 8.27637 4.5 8C4.5 7.72363 4.72388 7.5 5 7.5H9.793L8.1465 5.8535C7.95112 5.65812 7.95112 5.34175 8.1465 5.1465C8.34188 4.95125 8.65825 4.95113 8.8535 5.1465L11.3535 7.6465Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0_164_9">
					<rect width={16} height={16} fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	pagePreview: (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.2075 1.70712L5.9146 8.00002L12.2075 14.2929C12.3931 14.4809 12.4969 14.7346 12.4961 14.9988C12.4953 15.2629 12.39 15.516 12.2032 15.7028C12.0164 15.8896 11.7633 15.9949 11.4991 15.9957C11.235 15.9965 10.9812 15.8928 10.7933 15.7071L3.79329 8.70712C3.60576 8.51958 3.50041 8.26523 3.50041 8.00002C3.50041 7.73481 3.60576 7.48046 3.79329 7.29293L10.7933 0.292927C10.9812 0.107271 11.235 0.00351631 11.4991 0.00431832C11.7633 0.00512034 12.0164 0.110415 12.2032 0.297207C12.39 0.483999 12.4953 0.737114 12.4961 1.00128C12.4969 1.26544 12.3931 1.51919 12.2075 1.70712Z"
				fill="#003464"
			/>
		</svg>
	),
	pageNext: (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.79252 14.2929L10.0854 7.99998L3.79252 1.70707C3.60686 1.51915 3.50311 1.2654 3.50391 1.00124C3.50471 0.737073 3.61001 0.483958 3.7968 0.297166C3.98359 0.110373 4.23671 0.00507895 4.50087 0.00427707C4.76503 0.00347519 5.01878 0.107231 5.20671 0.292886L12.2067 7.29289C12.3942 7.48042 12.4996 7.73477 12.4996 7.99998C12.4996 8.26519 12.3942 8.51954 12.2067 8.70707L5.20671 15.7071C5.01878 15.8927 4.76503 15.9965 4.50087 15.9957C4.23671 15.9949 3.98359 15.8896 3.7968 15.7028C3.61001 15.516 3.50471 15.2629 3.50391 14.9987C3.50311 14.7346 3.60686 14.4808 3.79252 14.2929Z"
				fill="#003464"
			/>
		</svg>
	),
	payActive: (
		<svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx={5} cy={5} r={5} fill="#949494" />
		</svg>
	),
	viewDetails: (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.43139 7.99979C4.43139 6.0329 6.03124 4.43239 8.00025 4.43239C9.96718 4.43239 11.567 6.03287 11.567 7.99979C11.567 9.96713 9.96718 11.5669 8.00025 11.5669C6.03121 11.5669 4.43139 9.96713 4.43139 7.99979ZM0.092299 7.65945C-0.0307663 7.88204 -0.0307663 8.11754 0.092299 8.34034C1.69003 11.2094 4.71994 12.9918 8.00025 12.9918C11.2784 12.9918 14.3084 11.2095 15.9061 8.34034C16.0313 8.11754 16.0313 7.88204 15.9061 7.65945C14.3084 4.79036 11.2784 3.00824 8.00025 3.00824C4.71994 3.00824 1.69003 4.79036 0.092299 7.65945ZM8.00025 5.60151C6.67626 5.60151 5.60048 6.67725 5.60048 7.99979C5.60048 9.32229 6.67622 10.3981 8.00025 10.3981C9.32213 10.3981 10.3979 9.32232 10.3979 7.99979C10.3979 6.67725 9.32213 5.60151 8.00025 5.60151Z"
				fill="#B1B1B1"
			/>
		</svg>
	),
	edit: (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.6666 8.03075C12.298 8.03075 12 8.32947 12 8.69737V14.0307C12 14.3981 11.7013 14.6974 11.3334 14.6974H2C1.63197 14.6974 1.33337 14.3981 1.33337 14.0307V4.69737C1.33337 4.33006 1.63197 4.03075 2 4.03075H7.33337C7.70203 4.03075 8 3.73206 8 3.36412C8 2.99609 7.70203 2.69737 7.33337 2.69737H2C0.897344 2.69737 0 3.59472 0 4.69737V14.0307C0 15.1334 0.897344 16.0307 2 16.0307H11.3334C12.436 16.0307 13.3334 15.1334 13.3334 14.0307V8.69737C13.3334 8.32875 13.0352 8.03075 12.6666 8.03075Z"
				fill="#003464"
			/>
			<path
				d="M6.25075 7.42344C6.20442 7.46999 6.17268 7.52905 6.15944 7.59338L5.68813 9.95081C5.66616 10.0601 5.70082 10.1727 5.77944 10.2521C5.81047 10.283 5.84729 10.3075 5.88779 10.3242C5.9283 10.3409 5.97169 10.3495 6.0155 10.3494C6.03675 10.3494 6.05885 10.3474 6.08082 10.3428L8.4375 9.87147C8.50282 9.85803 8.56213 9.82678 8.60816 9.78003L13.8828 4.50534L11.5261 2.14878L6.25075 7.42344ZM15.5121 0.518812C14.8622 -0.13122 13.8048 -0.13122 13.1554 0.518812L12.2328 1.44141L14.5895 3.79809L15.5121 2.87537C15.8268 2.56141 16.0001 2.14269 16.0001 1.69737C16.0001 1.25206 15.8268 0.833374 15.5121 0.518812Z"
				fill="#003464"
			/>
		</svg>
	),
	delete: (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.94391 4.6875L2.77419 14.7105C2.83369 15.4333 3.44938 16 4.17494 16H11.8251C12.5507 16 13.1663 15.4333 13.2258 14.7105L14.0561 4.6875H1.94391ZM5.65581 14.125C5.41044 14.125 5.204 13.9341 5.18844 13.6855L4.71969 6.12303C4.70366 5.86441 4.90003 5.64194 5.15822 5.62591C5.426 5.60713 5.63888 5.80581 5.65534 6.06444L6.12409 13.6269C6.14069 13.8948 5.92878 14.125 5.65581 14.125ZM8.46875 13.6562C8.46875 13.9153 8.25909 14.125 8 14.125C7.74091 14.125 7.53125 13.9153 7.53125 13.6562V6.09375C7.53125 5.83466 7.74091 5.625 8 5.625C8.25909 5.625 8.46875 5.83466 8.46875 6.09375V13.6562ZM11.2803 6.12306L10.8116 13.6856C10.7962 13.9316 10.5911 14.1367 10.3144 14.1241C10.0563 14.1081 9.85987 13.8856 9.87591 13.627L10.3447 6.06447C10.3607 5.80584 10.5873 5.61769 10.8418 5.62594C11.1 5.64197 11.2963 5.86444 11.2803 6.12306ZM14.0938 1.875H11.2812V1.40625C11.2812 0.630813 10.6504 0 9.875 0H6.125C5.34956 0 4.71875 0.630813 4.71875 1.40625V1.875H1.90625C1.38847 1.875 0.96875 2.29472 0.96875 2.8125C0.96875 3.33022 1.38847 3.75 1.90625 3.75H14.0938C14.6115 3.75 15.0312 3.33022 15.0312 2.8125C15.0312 2.29472 14.6115 1.875 14.0938 1.875ZM10.3438 1.875H5.65625V1.40625C5.65625 1.14762 5.86637 0.9375 6.125 0.9375H9.875C10.1336 0.9375 10.3438 1.14762 10.3438 1.40625V1.875Z"
				fill="#F33636"
			/>
		</svg>
	),
	closeIcon: (
		<svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_19_1939)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.99999 0C15.5191 0 20 4.48093 20 9.99999C20 15.5191 15.5191 20 9.99999 20C4.48093 20 0 15.5191 0 9.99999C0 4.48093 4.48093 0 9.99999 0ZM9.01302 9.99999L6.25116 12.7628C6.18641 12.8275 6.13505 12.9044 6.10002 12.989C6.06498 13.0736 6.04694 13.1642 6.04694 13.2558C6.04694 13.3474 6.06498 13.438 6.10002 13.5226C6.13505 13.6072 6.18641 13.6841 6.25116 13.7488C6.3159 13.8136 6.39277 13.8649 6.47736 13.9C6.56195 13.935 6.65262 13.953 6.74418 13.953C6.83574 13.953 6.92641 13.935 7.011 13.9C7.09559 13.8649 7.17246 13.8136 7.2372 13.7488L9.99999 10.987L12.7628 13.7488C12.8275 13.8136 12.9044 13.8649 12.989 13.9C13.0736 13.935 13.1642 13.953 13.2558 13.953C13.3474 13.953 13.438 13.935 13.5226 13.9C13.6072 13.8649 13.6841 13.8136 13.7488 13.7488C13.8136 13.6841 13.8649 13.6072 13.9 13.5226C13.935 13.438 13.953 13.3474 13.953 13.2558C13.953 13.1642 13.935 13.0736 13.9 12.989C13.8649 12.9044 13.8136 12.8275 13.7488 12.7628L10.987 9.99999L13.7488 7.2372C13.8136 7.17246 13.8649 7.09559 13.9 7.011C13.935 6.92641 13.953 6.83574 13.953 6.74418C13.953 6.65262 13.935 6.56195 13.9 6.47736C13.8649 6.39277 13.8136 6.3159 13.7488 6.25116C13.6841 6.18641 13.6072 6.13505 13.5226 6.10002C13.438 6.06498 13.3474 6.04694 13.2558 6.04694C13.1642 6.04694 13.0736 6.06498 12.989 6.10002C12.9044 6.13505 12.8275 6.18641 12.7628 6.25116L9.99999 9.01302L7.2372 6.25116C7.17246 6.18641 7.09559 6.13505 7.011 6.10002C6.92641 6.06498 6.83574 6.04694 6.74418 6.04694C6.65262 6.04694 6.56195 6.06498 6.47736 6.10002C6.39277 6.13505 6.3159 6.18641 6.25116 6.25116C6.18641 6.3159 6.13505 6.39277 6.10002 6.47736C6.06498 6.56195 6.04694 6.65262 6.04694 6.74418C6.04694 6.83574 6.06498 6.92641 6.10002 7.011C6.13505 7.09559 6.18641 7.17246 6.25116 7.2372L9.01302 9.99999Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0_19_1939">
					<rect width={20} height={20} fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	uploadedFileSuccess: (
		<svg width={50} height={50} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M35.7143 10.7143V1.125L45.4643 12.5H37.5C37.0264 12.5 36.5722 12.3119 36.2373 11.977C35.9024 11.6421 35.7143 11.1879 35.7143 10.7143ZM37.5 16.0714C36.0792 16.0714 34.7166 15.507 33.7119 14.5024C32.7072 13.4977 32.1428 12.1351 32.1428 10.7143V0H8.92855C7.50775 0 6.14514 0.564412 5.14048 1.56907C4.13582 2.57373 3.57141 3.93634 3.57141 5.35714V44.6429C3.57141 46.0637 4.13582 47.4263 5.14048 48.4309C6.14514 49.4356 7.50775 50 8.92855 50H41.0714C42.4922 50 43.8548 49.4356 44.8595 48.4309C45.8641 47.4263 46.4286 46.0637 46.4286 44.6429V16.0714H37.5Z"
				fill="#272727"
			/>
		</svg>
	),
	call: (
		<svg width={16} height={16} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.1976 6.56385C8.58536 6.56385 7.98422 6.4681 7.41456 6.27984C7.13542 6.18464 6.79227 6.27198 6.6219 6.44695L5.4975 7.29576C4.19352 6.59969 3.39029 5.79672 2.70372 4.50253L3.52755 3.40742C3.74159 3.19367 3.81836 2.88143 3.72638 2.58846C3.53732 2.01581 3.44128 1.41495 3.44128 0.802448C3.4413 0.359974 3.08133 0 2.63888 0H0.802422C0.359974 0 0 0.359974 0 0.802422C0 5.87404 4.12599 10 9.1976 10C9.64005 10 10 9.64003 10 9.19758V7.36625C10 6.92383 9.64003 6.56385 9.1976 6.56385Z"
				fill="#003464"
			/>
		</svg>
	),
	email: (
		<svg width={16} height={1} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.48008 5.41506L5.64256 6.25541C5.30293 6.59623 4.70535 6.60358 4.35838 6.25541L3.52082 5.41506L0.512695 8.43295C0.624668 8.48473 0.748125 8.51563 0.879375 8.51563H9.12156C9.25281 8.51563 9.37623 8.48477 9.48816 8.43297L6.48008 5.41506Z"
				fill="#003464"
			/>
			<path
				d="M9.12109 1.48438H0.878906C0.747656 1.48438 0.624199 1.51527 0.512266 1.56705L3.72666 4.79211C3.72687 4.79232 3.72713 4.79236 3.72734 4.79258C3.72748 4.79272 3.72757 4.7929 3.7276 4.79309L4.77277 5.84172C4.88379 5.95273 5.11625 5.95273 5.22727 5.84172L6.27223 4.79326C6.27223 4.79326 6.27248 4.79279 6.2727 4.79258C6.2727 4.79258 6.27316 4.79232 6.27338 4.79211L9.48769 1.56703C9.37576 1.51523 9.25234 1.48438 9.12109 1.48438ZM0.0934766 1.9768C0.0355469 2.09395 0 2.22398 0 2.36328V7.63672C0 7.77601 0.0355078 7.90605 0.093457 8.0232L3.1068 5.0001L0.0934766 1.9768ZM9.90652 1.97676L6.89322 5.0001L9.90652 8.02324C9.96445 7.90609 10 7.77605 10 7.63672V2.36328C10 2.22395 9.96445 2.09391 9.90652 1.97676Z"
				fill="#003464"
			/>
		</svg>
	),
};
