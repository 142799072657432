import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import store from "./store";
import { App as AppWrapper, ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorFallbackPage, SiteLoader, alias } from "./helpers/utils";
import { ErrorBoundary } from "react-error-boundary";

// ToastHandler
import ToastHandler from "./helpers/ToastHandler";

// Translation
import "./helpers/i18n";

// CSS
import "./Assets/CSS/custom.css";
import "./Assets/CSS/style.css";
import "./Assets/CSS/component.css";

// Auth CSS
import "./Auth/CSS/style.css";

// Component CSS
import "./Components/CSS/style.css";

// Admin CSS
import "./Admin/CSS/style.css";
import "./Admin/CSS/admin.css";

// View CSS
import "./View/CSS/style.css";

// Swiper Js
import "swiper/css";
import "swiper/css/navigation";
import { sideMenuBarData } from "./Assets/navigation";
// import { Dashboard } from "./Admin/Pages/Dashboard";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Auth Pages
const AuthIndex = lazy(() => import("./Auth/AuthIndex"));
const Login = lazy(() => import("./Auth/Pages/Login"));
const ForgotPassword = lazy(() => import("./Auth/Pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./Auth/Pages/ResetPassword"));
// admin pages
const Dashboard = lazy(() => import("./Admin/Pages/Dashboard"));
const Customers = lazy(() => import("./Admin/Pages/Customer"));
const Bills = lazy(() => import("./Admin/Pages/Bill"));
const Setting = lazy(() => import("./Admin/Pages/Setting"));
//email template
const EmailTemplate = lazy(() => import("./Admin/Pages/EmailTemplate"));
const EditEmailTemplate = lazy(() => import("./Admin/Pages/EditEmailTemplate"));
//static pages
const StaticPages = lazy(() => import("./Admin/Pages/StaticPages"));
const EditStaticPage = lazy(() => import("./Admin/Pages/EditStaticPage"));
const Privacy = lazy(() => import("./Admin/Views/Privacy"));
const Terms = lazy(() => import("./Admin/Views/TermsAndConditions"));
const AboutUs = lazy(() => import("./Admin/Views/AboutUs"));
const ContactUs = lazy(() => import("./Admin/Views/ContactUs"));

// Comman Pages

// Learner Pages
const ViewIndex = lazy(() => import("./View/ViewIndex"));
const Home = lazy(() => import("./Admin/Pages/Home"));

// Parent Pages
const AdminIndex = lazy(() => import("./Admin/AdminIndex"));

/** Error Page */
const PageNotFound = lazy(() => import("./Components/Pages/Error"));

// CSSIndex2
const CSSINDEX2 = lazy(() => import("./View/CSSIndex2"));

root.render(
	<React.StrictMode>
		<ConfigProvider
			theme={{
				token: {
					fontFamily: "Nunito Sans Regular",
				},
			}}
		>
			<AppWrapper>
				<Provider store={store}>
					{/* live */}
					{/* <BrowserRouter> */}
					{/* dev */}
					<BrowserRouter basename={alias}>
						<Suspense fallback={<SiteLoader />}>
							<ErrorBoundary FallbackComponent={ErrorFallbackPage}>
								{/* Route List */}
								<Routes>
									{/* Auth */}
									<Route path="/auth" element={<AuthIndex />}>
										<Route path="login" element={<Login />} />
										{/* <Route path="sign-up" element={<SignUp />} /> */}
										{/* <Route path="sign-up/:role" element={<SignUp />} /> */}
										<Route path="forgot-password" element={<ForgotPassword />} />
										<Route path="forgot-password/:token" element={<ResetPassword />} />
									</Route>
									{/* Auth */}

									{/* View */}
									<Route path="/" element={<ViewIndex roleName={""} />}>
										<Route exact path="" element={<Home />} />

										<Route path="*" element={<PageNotFound />} />
									</Route>
									{/* View */}

									{/* Admin */}
									<Route path="/admin" element={<AdminIndex roleName={"admin"} navigationData={sideMenuBarData} />}>
										<Route exact path="" element={<Dashboard />} />
										<Route path="dashboard" element={<Dashboard />} />
										<Route path="customers" element={<Customers />} />
										<Route path="bill-management" element={<Bills />} />
										<Route path="settings" element={<Setting />} />
										<Route path="*" element={<PageNotFound />} />

										<Route path="static-pages">
											<Route path="" element={<StaticPages />} />
											<Route exact path="edit-staticpage/:id" element={<EditStaticPage />} />
										</Route>
										{/* email template */}
										<Route path="email-template">
											<Route path="" element={<EmailTemplate />} />
											<Route exact path="edit/:id" element={<EditEmailTemplate />} />
										</Route>
									</Route>
									{/* Admin */}

									{/* static pages */}
									<Route path="/" element={<CSSINDEX2 />}>
										<Route path="privacy-policy" element={<Privacy />} />
										<Route path="terms-and-conditions" element={<Terms />} />
										<Route path="about-us" element={<AboutUs />} />
										<Route path="contact-us" element={<ContactUs />} />
									</Route>

									{/* static pages */}

									<Route path="*" element={<PageNotFound />} />

									{/* Comman */}
								</Routes>
								{/* Route List End */}
							</ErrorBoundary>
						</Suspense>
					</BrowserRouter>
					<ToastHandler />
				</Provider>
			</AppWrapper>
		</ConfigProvider>
	</React.StrictMode>
);
