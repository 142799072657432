import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorLogo from "../Assets/Images/Icons/somethingWentWrongError.svg";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import imageCompression from "browser-image-compression";

/** Environment */
export let API_URL;
export let API_KEY;
export let BUCKET_URL;
export let STORE_URL;
export let PROFILE_URL;
export let STATIC_PAGE_URL;
export let USER_SAMPLE_CSV;
export const API_VERSION = `v1`;

export const IS_DEV = 2; // For local set it to 0, for dev-server set it to 1,  for live-server set it to 2

// export const alias = "/infinitywaste-web";
export const alias = IS_DEV === (2 || 0) ? "" : "/infinitywaste-web";
export const appName = "NimblePay";

/** User Roles */
export const ROLE = {
	ADMIN: "admin",
	USER: "user",
};

/** Client URL */
export let CLIENT_URL = IS_DEV ? "http://localhost:3000" + alias : "https://dev.3rddigital.com" + alias;

/** API URL */
switch (IS_DEV) {
	case 0:
		/** Client URL */
		CLIENT_URL = "http://localhost:3000" + alias;
		/** API URL */
		API_URL = `http://localhost:8052/api/${API_VERSION}`;
		STORE_URL = `http://localhost:8052/` + "public/common";
		API_KEY =
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJzZXJ2ZXJFbnYiOiJsb2NhbCIsImNyZWF0ZWRfYXQiOiIyMDI0LTA1LTIyVDA2OjI2OjAwLjAyMloiLCJpYXQiOjE3MTYzNTkxNjAsImF1ZCI6ImFkbWluIn0.TlL_35phFv4-aewV9grTcsscEyvCVW47aAFWNjvvqeU";
		BUCKET_URL = "https://s3.ap-south-1.amazonaws.com/infinity-waste";
		PROFILE_URL = BUCKET_URL + "/profile";
		// STATIC_PAGE_URL = "http://localhost:3000/infinitywaste-web/";
		STATIC_PAGE_URL = "http://localhost:3000" + alias + "/";
		USER_SAMPLE_CSV = "http://localhost:8052/public/bulk-insert/userInsert.csv";
		break;
	case 1:
		/** Client URL */
		CLIENT_URL = "https://dev.3rddigital.com" + alias;

		API_URL = `https://dev.3rddigital.com/infinity-waste-api/api/${API_VERSION}`;
		STORE_URL = `https://dev.3rddigital.com/infinity-waste-api` + "/public/common";
		API_KEY =
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJzZXJ2ZXJFbnYiOiJkZXYiLCJjcmVhdGVkX2F0IjoiMjAyNC0wNS0zMFQxMDo0NjoxMC40MjNaIiwiaWF0IjoxNzE3MDY1OTcwLCJhdWQiOiJhZG1pbiJ9.iIvbk1uQQOstLAhzSLoVH5K71AhziIhii8hDAO7cH7c";
		BUCKET_URL = "https://s3.ap-south-1.amazonaws.com/infinity-waste";
		PROFILE_URL = BUCKET_URL + "/profile";
		// STATIC_PAGE_URL = "https://dev.3rddigital.com/infinitywaste-web/";
		STATIC_PAGE_URL = "https://dev.3rddigital.com" + alias + "/";
		USER_SAMPLE_CSV = "https://dev.3rddigital.com/infinity-waste-api/public/bulk-insert/userInsert.csv";

		break;
	case 2:
		/** Client URL */
		CLIENT_URL = "https://nimblepay.net" + alias;

		API_URL = `https://nimblepay.net/api/${API_VERSION}`;
		STORE_URL = `https://nimblepay.net/api/` + "/public/common";
		API_KEY =
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJzZXJ2ZXJFbnYiOiJwcm9kIiwiY3JlYXRlZF9hdCI6IjIwMjQtMDYtMjVUMTA6Mzk6MDguNzY5WiIsImlhdCI6MTcxOTMxMTk0OCwiYXVkIjoiYWRtaW4ifQ.Alw9lu895ITC8_7P-XtgElXWJ-2zZdRC8Iwspp7iyeI";
		BUCKET_URL = "https://s3.ap-south-1.amazonaws.com/infinity-waste";
		PROFILE_URL = BUCKET_URL + "/profile";
		// STATIC_PAGE_URL = "https://dev.3rddigital.com/infinitywaste-web/";
		STATIC_PAGE_URL = "https://nimblepay.net" + alias + "/";
		USER_SAMPLE_CSV = "https://nimblepay.net/api/public/bulk-insert/userInsert.csv";

		break;
	case 4:
		/** Client URL */
		CLIENT_URL = "http://localhost:3000" + alias;
		/** API URL */
		API_URL = `http://localhost:8052/api/${API_VERSION}`;
		STORE_URL = `http://localhost:8052/` + "public/common";
		API_KEY =
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJzZXJ2ZXJFbnYiOiJsb2NhbCIsImNyZWF0ZWRfYXQiOiIyMDI0LTA2LTExVDEzOjQwOjExLjE2OFoiLCJpYXQiOjE3MTgxMTMyMTEsImF1ZCI6ImFkbWluIn0.PXrMk8nC7-KceA9JqBVN0XaRv22tzvgsUDBuacQZZqM";
		BUCKET_URL = "https://s3.ap-south-1.amazonaws.com/infinity-waste";
		PROFILE_URL = BUCKET_URL + "/profile";
		// STATIC_PAGE_URL = "http://localhost:3000/infinitywaste-web/";
		STATIC_PAGE_URL = "http://localhost:3000" + alias + "/";
		USER_SAMPLE_CSV = "http://localhost:8052/public/bulk-insert/userInsert.csv";

		break;
	default:
}

export const redirectPath = {
	home: alias?.length ? alias : "/",
	aboutUs: alias?.length ? alias + "/about-us" : "/about-us",
	contactUs: alias?.length ? alias + "/contact-us" : "/contact-us",
	terms: alias?.length ? alias + "/terms-and-conditions" : "/terms-and-conditions",
	privacy: alias?.length ? alias + "/privacy-policy" : "/privacy-policy",
};
/** Common Variables */
export const EMAIL_REGEX = /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const ONLY_NUMBERS_REGEX = /^[0-9]+$/;
export const ONLY_ALPHABETS_REGEX = /^[A-Za-z ]+$/;

/** Date Functions Starts */
export const dateFormat = "DD MMM, YYYY";
export const dataYYYY = "YYYY";
export const dataMMMM = "MMMM";
export const dataMMM = "MMM";
export const dataDate = "DD";
export const dataTime = "hh:MM A";
export const dateWithTime = "DD MMMM YYYY - hh:MM A";
export const dayjsFormat = "MM/DD/YYYY";
export const dayjsFormatWithTime = "MM/DD/YYYY - hh:MM A";
export const momentDDMMYY = (data) => moment(data).format(dateFormat);
export const momentYYYY = (data) => moment(data).format(dataYYYY);
export const momentMMMM = (data) => moment(data).format(dataMMMM);
export const momentMMM = (data) => moment(data).format(dataMMM);
export const momentDD = (data) => moment(data).format(dataDate);
export const momentHHMMA = (data) => moment(data).format(dataTime);
export const momentFullTime = (data) => moment(data).format(dateWithTime);
export const dateFormatter = (data, formate) => moment(data).format(formate);
export function convertUTCtoLocalDateTime(utcDateTimeString) {
	const utcDate = new Date(utcDateTimeString);

	const localYear = utcDate.getFullYear();
	const localMonth = (utcDate.getMonth() + 1).toString().padStart(2, "0"); // Month starts from 0, so adding 1 to adjust
	const localDay = utcDate.getDate().toString().padStart(2, "0");

	const formattedDate = `${localMonth}-${localDay}-${localYear}`;

	const localHours = utcDate.getHours().toString().padStart(2, "0");
	const localMinutes = utcDate.getMinutes().toString().padStart(2, "0");
	const localSeconds = utcDate.getSeconds().toString().padStart(2, "0");

	const formattedTime = `${localHours}:${localMinutes}:${localSeconds}`;

	return formattedDate + " " + formattedTime;
}
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export function convertUTCtoLocalDateTime1(utcDateTimeString) {
	const utcDate = new Date(utcDateTimeString);
	console.log("utcDate", utcDate);

	const localYear = utcDate.getFullYear();
	const localMonth = (utcDate.getMonth() + 1).toString().padStart(2, "0"); // Month starts from 0, so adding 1 to adjust
	const localDay = utcDate.getDate().toString().padStart(2, "0");

	// const formattedDate = `${localMonth}-${localDay}-${localYear}`;
	const formattedDate = `${localDay} ${monthNames[localMonth - 1]}, ${localYear}`;

	const localHours = utcDate.getHours().toString().padStart(2, "0");
	const localMinutes = utcDate.getMinutes().toString().padStart(2, "0");
	const localSeconds = utcDate.getSeconds().toString().padStart(2, "0");

	const period = localHours >= 12 ? "PM" : "AM";
	const formattedHours = (((localHours + 11) % 12) + 1).toString().padStart(2, "0");

	// const formattedTime = `${localHours}:${localMinutes}:${localSeconds}`;
	// const formattedTime = `${formattedHours}:${localMinutes}:${localSeconds} ${period}`;
	const formattedTime = `${formattedHours}:${localMinutes} ${period}`;

	return formattedDate + "-" + formattedTime;
}
/** Date Functions Ends */

/**number format */
export const formatNumber = (num) => {
	// Check if the number is an integer
	if (num) {
		if (Number.isInteger(num)) {
			return num;
		} else {
			// If it's not an integer, format it to 2 decimal places
			return num.toFixed(2);
		}
	} else {
		return null;
	}
};
/**number format */
/** Reset Function to remove data after login */
export const Reset = () => {
	localStorage.removeItem("userAccessToken");
};

/** Error Handling Functions Starts */
export const dispatchLoading = (dispatch, scope, status) => dispatch({ type: "SET_LOADING", payload: { scope, status } });

export const dispatchToast = (dispatch, scope, status) => dispatch({ type: "SET_TOAST", payload: { scope, status } });

export const dispatchError = (dispatch, scope, status) => dispatch({ type: "SET_ERROR", payload: { scope, status } });

export const catchHandler = (dispatch, scope) => (err) => {
	console.log("Err catchHandler :", err);
	let errMsg = err?.response?.data?.msg
		? err?.response?.data?.msg
		: err?.response?.data?.message
		? err?.response?.data?.message
		: err?.response?.data?.error
		? err?.response?.data?.error
		: "Something went wrong";
	dispatchToast(dispatch, "error", errMsg);
	if (err.code === "ERR_NETWORK") dispatchToast(dispatch, "error", "Unable to connect to server");
	else if (err?.response?.status !== 401) {
		dispatchError(dispatch, scope, [errMsg]);
	} else if (err?.response?.status === 401) {
		localStorage.setItem("isLoggedIn", false);
		dispatchError(dispatch, scope, [errMsg]);
		// window.location.assign(`${CLIENT_URL}/auth/login`);
	} else dispatchToast(dispatch, "error", errMsg);
	dispatchLoading(dispatch, scope, false);
	dispatchError(dispatch, scope, [errMsg]);
};

export const elseHandler = (dispatch, scope, data) => {
	// dispatchToast(dispatch, "error", data?.msg);
	dispatchToast(dispatch, "error", data?.message);
	dispatchError(dispatch, scope, data?.error);
};

export const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
	// Call resetErrorBoundary() to reset the error boundary and retry the render.

	// Use Navigate
	const navigate = useNavigate();

	// Use Translation
	const { t } = useTranslation();

	// Use location
	const { pathname } = useLocation();

	// Use Ref
	const originalPathname = useRef(pathname);

	// Goto previous Page
	const goToBack = () => {
		navigate(-1);
	};

	// Use Effect
	useEffect(() => {
		if (pathname !== originalPathname.current) resetErrorBoundary();
	}, [pathname, resetErrorBoundary]);

	return (
		<div className="text-center w-100 d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
			<div>
				<div className="px-3 mb-4 header-logo">
					<img src={ErrorLogo} alt="header logo" className="img-fluid" onClick={(e) => navigate("/")} />
				</div>
				<h5 className="ff_rg mb-1 f18">{t("error.somethingWrong")}</h5>
				<p className="ff_rg f14">
					{t("error.thereWasProblem")}
					<span className="d-block ff_rg f14 mx-3">{t("error.pleaseRefresh")}</span>
				</p>
				<p className="ff_rg f14 mt-3 text-secondary">
					{t("error.somethingNot")} ?{" "}
					<span className="ff_md f14 text-dark">
						{t("error.contact")} :{" "}
						<Link className="ff_md f14 text-dark" to="mailto: support@companyname.com">
							support@companyname.com
						</Link>{" "}
					</span>
				</p>
				<button className="btn btn-primary text-uppercase fs-6 px-3 txt-white mt-3" onClick={() => goToBack()}>
					{t("error.goBack")}
				</button>
			</div>
		</div>
	);
};
/** Error Handling Functions Ends */

/**
 * Protected Route for Client
 * @param {Object} routeComponent - Object with route data
 * @param {String} routeComponent.roleName - Role of user
 * @param {Function} routeComponent.children - Child component / page
 * @returns {Function} Child Component
 */
export const ProtectedRoute = ({ roleName, children, navigate }) => {
	const { role } = useSelector((state) => state.auth);
	// const navigate = useNavigate();
	if (role === roleName) {
		return <React.Fragment>{children}</React.Fragment>;
	} else {
		navigate("/");
	}
};

/**
 * Protected Route for Admin
 * @param {Object} routeComponent - Object with route data
 * @param {String} routeComponent.roleName - Role of user
 * @param {Function} routeComponent.children - Child component / page
 * @returns {Function} Child Component
 */
export const ProtectedRouteAdmin = ({ roleName, children }) => {
	const { role } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	if (localStorage.getItem("userAccessToken") !== null && localStorage.getItem("userAccessToken") !== undefined && (role === "admin" || role === "sub-admin"))
		return <React.Fragment>{children}</React.Fragment>;
	else navigate(`/`, { replace: true });
};

/** Loader */
export const SiteLoader = ({ text }) => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	if (pathname === "/") {
		return null;
	}
	return (
		<div
			style={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				textAlign: "center",
				width: "calc(100% - 1rem)",
			}}
		>
			<div className="Loader">
				<div className="d-block">
					<div className="spinner-border" role="status"></div>
				</div>
			</div>
			<p style={{ fontSize: "1.5rem", lineHeight: "1.1" }}>{text !== undefined ? text : t("pleaseWait")}</p>
		</div>
	);
};

/** Global Button Loader */
export const BtnLoader = () => {
	return (
		<Spin
			indicator={
				<LoadingOutlined
					style={{
						fontSize: 16,
						color: "#fff",
					}}
					spin
					className="me-2"
				/>
			}
		/>
	);
};

export const TableLoader = () => {
	return (
		<div className="loader-container">
			<div className="loader">
				<div className="spinner-border" role="status"></div>
			</div>
		</div>
	);
};

/** Function to compress image before API call */
export const imageCompressorFn = async (file, setState) => {
	const options = {
		maxSizeMB: 1,
		maxWidthOrHeight: 1920,
		useWebWorker: true,
	};
	try {
		const compFile = await imageCompression(file, options);
		setState(compFile);
	} catch (error) {
		setState(file);
	}
};
