import { combineReducers, configureStore } from "@reduxjs/toolkit";

const errorReducer = (state = { authError: undefined, carError: undefined }, action) => {
	switch (action.type) {
		case "SET_ERROR":
			const temp = {};
			const { payload } = action;

			if (payload.status && payload.status.length) {
				payload.status &&
					payload?.status.forEach((ele) => {
						if (ele.param === "_error") {
							ele.nestedErrors?.forEach((ele1) => {
								const key = ele1.param.split(".").pop();
								temp[key] = ele1.msg;
							});
						} else if (ele.param?.includes("updates.")) {
							const key = ele.param.split(".").slice(1).join(".");
							temp[key] = ele.msg;
						} else {
							temp[ele.path] = ele.msg;
						}
					});
			}

			return { ...state, [`${payload.scope}Error`]: temp };

		default:
			return state;
	}
};

const loadingReducer = (state = { authLoading: false }, action) => {
	switch (action.type) {
		case "SET_LOADING":
			return Object.assign({}, state, {
				[`${action.payload.scope}Loading`]: action.payload.status,
			});
		default:
			return state;
	}
};

const msgReducer = (state = { success: undefined, error: undefined }, action) => {
	switch (action.type) {
		case "SET_TOAST":
			return Object.assign({}, state, {
				[`${action.payload.scope}`]: action.payload.status || "Something went wrong!",
			});
		case "CLEAR_TOAST":
			return { ...state, error: undefined, success: undefined };
		default:
			return state;
	}
};

const authReducer = (
	state = {
		isLoggedIn: false,
		user: undefined,
	},
	action
) => {
	switch (action.type) {
		case "AUTH_LOAD_SUCCESS":
			return {
				...state,
				isLoggedIn: true,
				user: action?.payload,
			};

		case "AUTH_LOAD_FAILURE":
			return {
				...state,
				isLoggedIn: false,
			};

		case "LOGIN_SUCCESS":
			return {
				isLoggedIn: true,
				user: action.payload,
			};

		case "LOGOUT_SUCCESS":
			return {
				isLoggedIn: false,
				user: undefined,
			};

		default:
			return state;
	}
};

const navCountReducer = (
	state = {
		active_customer: 0,
		bill_count: 0,
	},
	action
) => {
	switch (action.type) {
		case "NAV_CUSTOMER_COUNT":
			return {
				...state,

				active_customer: action?.payload,
			};

		case "NAV_BILL_COUNT":
			return {
				...state,
				bill_count: action?.payload,
			};

		default:
			return state;
	}
};

const userReducer = (
	state = {
		list: undefined,
		userData: [],
		userCount: 0,
		userDetails: undefined,
		detail: undefined,
	},
	action
) => {
	switch (action.type) {
		case "USER_LIST_SUCCESS":
		case "USER_SEARCH_SUCCESS":
			return {
				...state,
				list: action.payload.list,
				userCount: action.payload.count,
				userData: action.payload.userData,
			};
		case "FETCH_USER_DETAILS":
			return {
				...state,
				userDetails: action.payload,
			};
		case "CLEAR_USER_DETAILS":
			return {
				...state,
				userDetails: undefined,
			};
		case "USER_TOGGLE_SUCCESS":
			return {
				...state,
				userDetails: { ...state?.userDetails, isActive: !state?.userDetails?.isActive },
			};
		case "USER_SEARCH_CLEAR":
			return {
				...state,
				list: undefined,
				userCount: 0,
			};
		default:
			return state;
	}
};
const billReducer = (
	state = {
		list: undefined,
		billCount: 0,
		billDetails: undefined,
		detail: undefined,
	},
	action
) => {
	switch (action.type) {
		case "BILL_LIST_SUCCESS":
		case "BILL_SEARCH_SUCCESS":
			return {
				...state,
				list: action.payload.list,
				billCount: action.payload.count,
			};
		case "FETCH_BILL_DETAILS":
			return {
				...state,
				billDetails: action.payload,
			};
		case "CLEAR_BILL_DETAILS":
			return {
				...state,
				billDetails: undefined,
			};
		case "BILL_TOGGLE_SUCCESS":
			return {
				...state,
				billDetails: { ...state?.billDetails, isActive: !state?.billDetails?.isActive },
			};
		case "BILL_SEARCH_CLEAR":
			return {
				...state,
				list: undefined,
				billCount: 0,
			};
		default:
			return state;
	}
};

const dashboardReducer = (state = {}, action) => {
	switch (action.type) {
		case "DASHBOARD_SUCCESS":
			return {
				...state,
				dashboardItem: action.payload.value,
			};

		case "CLEAR_DASHBOARD": {
			return {};
		}

		default:
			return state;
	}
};

const settingReducer = (
	state = {
		billing: {
			duration_month: 0,
			price: 0,
		},
		notification: {
			bill_email: 1,
			bill_sms: 1,
			payment_email: 1,
			payment_sms: 1,
		},
		contact: {
			support_email: "",
			support_country_code: "",
			support_phone: "",
		},
	},
	action
) => {
	switch (action.type) {
		case "SETTING_LIST_SUCCESS":
		case "SETTING_SEARCH_SUCCESS":
			return {
				...state,
				billing: action.payload.billing,
				notification: action.payload.notification,
				contact: action.payload.contact,
			};

		case "CLEAR_SETTING_DETAILS":
			return {
				...state,
				billing: undefined,
				notification: undefined,
				contact: undefined,
			};

		default:
			return state;
	}
};
const staticReducer = (
	state = {
		faqList: undefined,
		staticPageList: undefined,
		staticPageCount: undefined,
		staticPageDetails: undefined,
		singleStaticPage: undefined,
	},
	action
) => {
	switch (action.type) {
		case "STATIC_PAGE_LIST_SUCCESS":
			return {
				...state,
				staticPageList: action.payload.list,
				staticPageCount: action.payload.count,
			};
		case "STATIC_PAGE_DETAILS_SUCCESS":
			return {
				...state,
				staticPageDetails: action.payload,
			};
		case "CLEAR_STATIC_PAGE_DETAILS":
			return {
				...state,
				staticPageDetails: undefined,
			};
		case "SINGLE_STATIC_PAGE_SUCCESS":
			return {
				...state,
				singleStaticPage: action.payload,
			};
		case "FAQ_LIST_SUCCESS":
			return {
				...state,
				faqList: action.payload,
			};
		default:
			return state;
	}
};
const emailReducer = (
	state = {
		faqList: undefined,
		emailTemplateList: undefined,
		emailTemplateCount: undefined,
		emailTemplateDetails: undefined,
		singleEmailTemplate: undefined,
	},
	action
) => {
	switch (action.type) {
		case "EMAIL_TEMPLATE_LIST_SUCCESS":
			return {
				...state,
				emailTemplateList: action.payload.list,
				emailTemplateCount: action.payload.count,
			};
		case "EMAIL_TEMPLATE_DETAILS_SUCCESS":
			return {
				...state,
				emailTemplateDetails: action.payload,
			};
		case "CLEAR_EMAIL_TEMPLATE_DETAILS":
			return {
				...state,
				emailTemplateDetails: undefined,
			};
		case "SINGLE_EMAIL_TEMPLATE_SUCCESS":
			return {
				...state,
				singleEmailTemplate: action.payload,
			};

		default:
			return state;
	}
};
const store = configureStore({
	reducer: combineReducers({
		error: errorReducer,
		loading: loadingReducer,
		msg: msgReducer,
		auth: authReducer,
		nav: navCountReducer,
		user: userReducer,
		bill: billReducer,
		dashboard: dashboardReducer,
		setting: settingReducer,
		static: staticReducer,
		email: emailReducer,
	}),
});

export default store;
