import svg from "./svg";

export const sideMenuBarData = [
	{
		id: 1,
		path: "dashboard",
		label: "Dashboard",
		image: svg?.dashboard,
	},
	{
		id: 2,
		path: "users",
		label: "Users",
		image: svg?.users,
	},
];
